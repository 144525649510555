import React from 'react';
import styled from "styled-components";
import TextInput from "../TshiamisoComponents/TextInput";
import Banner from "../Banner/Banner";
import Country from "./Country";

const VerifyIdentityFields = ({setField, applicantReviewPayload, entity}) => {
    const renderFieldMismatchWarning = (field, fieldName) => {
        if (applicantReviewPayload[field] === entity[field]) return null;
        if (!applicantReviewPayload[field]) return null;
        return <Banner
            type={"info"}
            messages={[{title: `You have changed the ${fieldName}. The user entered value will be updated with the new value entered above`}]}/>
    };

    const renderCountryMismatchWarning = () => {
        if (applicantReviewPayload.nationality === entity.country) return null;
        if (!applicantReviewPayload.nationality) return null;
        return <Banner
            type={"info"}
            messages={[{title: `You have changed the Country. The user entered value will be updated with the new value entered above`}]}/>
    };

    const renderIdentificationFields = () => {
        if (applicantReviewPayload.document_type !== "Identification Document" && applicantReviewPayload.document_type !== "Claimant Identification Document") return null;
        if (applicantReviewPayload.valid_for_submission === "No") return null;
        return (
            <React.Fragment>
                <TextInput
                    label={"Identification Number"}
                    value={applicantReviewPayload.identification_number}
                    onChange={(value) => setField("identification_number", value)}
                    helperText={"*This field must be filled to continue"}
                />
                {renderFieldMismatchWarning("identification_number", "Identification Number")}

                <Country
                    initialValue={entity.country}
                    onChange={(value) => setField("nationality", value)}
                />
                {renderCountryMismatchWarning()}

                <TextInput
                    label={"First Name"}
                    value={applicantReviewPayload.first_name || entity.first_name}
                    onChange={(value) => setField("first_name", value)}
                />
                {renderFieldMismatchWarning("first_name", "First Name")}
                <TextInput
                    label={"Last Name"}
                    value={applicantReviewPayload.last_name || entity.last_name}
                    onChange={(value) => setField("last_name", value)}
                />
                {renderFieldMismatchWarning("last_name", "Last Name")}
            </React.Fragment>
        );
    };

    const renderGeneralFields = () => {
        if (applicantReviewPayload.document_type === "Identification Document") return null;
        if (applicantReviewPayload.document_type === "Claimant Identification Document") return null;

        return (
            <React.Fragment>
                <TextInput
                    label={"Industry Number"}
                    value={applicantReviewPayload.industry_number || entity.industry_number}
                    onChange={(value) => setField("industry_number", value)}
                />
                {renderFieldMismatchWarning("industry_number", "Industry Number")}
            </React.Fragment>
        );
    };

    return (
        <Container>
            {applicantReviewPayload.valid_for_submission !== "No" &&
            <Title>
                Please verify the information below
            </Title>}
            {renderIdentificationFields()}
            {renderGeneralFields()}

        </Container>
    );
};

export default VerifyIdentityFields;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px;
`

const Title = styled.div`
  opacity: 0.8;
  color: #000000;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 20px 0;
`;