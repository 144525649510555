import React, {useContext} from 'react';
import {ReactComponent as ArrowLeft} from "./arrow-left.svg";
import {ReactComponent as ArrowRight} from "./arrow-right.svg";
import styled from "styled-components";
import {ApplicantContext} from "../../ApplicantContext";
import {IconButton} from "@material-ui/core";

const DocumentPreviewPaginator = () => {
    const applicantContext = useContext(ApplicantContext);
    const {applicant, setActiveDocument, activeDocument} = applicantContext;
    const {documents} = applicant || {};



    const renderPaginator = () => {
        return documents.map((document, index) => {
            return (
                <IconButton key={index} onClick={() => {
                    if (activeDocument === index) return;
                    setActiveDocument(index)
                }}>
                    <IconText active={index===activeDocument}>{index + 1}</IconText>
                </IconButton>
            )
        })
    };

    return (
        <PaginatorDiv>
            <IconButton
                disabled={parseInt(activeDocument) === 0}
                onClick={()=>setActiveDocument(activeDocument - 1)}
            >
                <ArrowLeft/>
            </IconButton>

            {renderPaginator()}

            <IconButton
                disabled={parseInt(activeDocument) === documents.length - 1}
                onClick={()=>setActiveDocument(activeDocument + 1)}
            >
                <ArrowRight/>
            </IconButton>
        </PaginatorDiv>
    );
};

export default DocumentPreviewPaginator;

const PaginatorDiv = styled.div`
  color: #ffffff;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const IconText = styled.div`
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: -0.18px;
  line-height: 19px;
  opacity: ${(props)=>props.active ? 1 : 0.2}
`;