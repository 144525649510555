const theme = {
    palette: {
        background: {
            default: '#ffffff'
        },
        warning: {
            main: '#e9bf7a',
            light: 'rgba(188,166,120,0.1)',
            dark: 'rgba(188,166,120,1)'
        },
        success: {
            main: '#6caa9c',
            light: 'rgba(94,157,143,0.1)',
            dark: 'rgba(94,157,143,1)'
        },
        error: {
            main: '#d29292',
            light: '#ffc3c2',
            dark: '#9f6464'
        },
        primary: {
            main: '#007B51',
            light: '#9DD000',
            dark: '#000d1a'
        }
    }
};

export default theme;