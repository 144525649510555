import React, {useEffect, useState} from 'react';
import Select from "../TshiamisoComponents/Select";

const RejectionReason = ({initialValue, onChange}) => {

    const [value, setValue] = useState(initialValue);
    const options = [
        {label: "Document Unclear", value: "Document Unclear"},
        {label: "Document Not Valid", value: "Document Not Valid"},
        {label: "Document Incomplete", value: "Document Incomplete"}
    ]

    const onUpdate = (newValue) => {
        setValue(newValue)
        onChange && onChange(newValue)
    }

    useEffect(() => {
        onUpdate(initialValue)
    }, [initialValue])

    return (
        <Select
            label={"Rejection Reason"}
            id={"rejection_reason"}
            options={options}
            value={value}
            onChange={onUpdate}
        />
    );
};

export default RejectionReason;