import React from 'react';
import styled from 'styled-components'
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 15px;
`

const PersonTitle = styled.div`
  color: #333333;
  font-size: 28px;
  font-weight: bold;
  margin-right: 30px;
`

const Divider = styled.div`
  box-sizing: border-box;
  height: 24px;
  width: 1px;
  border: 1px solid #979797;
  opacity: 0.2;
`

const PersonID = styled.div`
  font-size: 14px;
  padding-left: 10px;
`


function UserHeader({name, id, reference_number}) {
    return (
        <Header>
            <PersonTitle>{name} <CopyToClipboard text={name}/> </PersonTitle>
            <Divider/>
            <PersonID>Identification Number: {id} <CopyToClipboard text={id}/></PersonID>
            <Divider/>
            <PersonID>Pre-Lodgement Reference Number: {reference_number} <CopyToClipboard text={reference_number}/></PersonID>
        </Header>
    )
}

export default UserHeader;
