import PdfDialog from "../dialogs/pdf_dialog";
import React from "react";
import PDFViewer from './pdf_viewer';
import * as PropTypes from "prop-types";

function DialogPDFViewer(props) {

    const dialogContent = () => {
        return (
            <PDFViewer
                file_pdf_url={props.file_url}
                onClose={() => {props.onClose()}}
            />
        );
    };

    return (
        <PdfDialog
            title={"PDF Viewer"}
            content={dialogContent()}
            open={props.dialog_open}
            handleClose={() => {props.onClose()}}
        />
    )    
}


DialogPDFViewer.propTypes = {
    /**
     * The URL to the PDF file (base 64 generally used)
     */
    file_url: PropTypes.string.isRequired,
    /**
     * The function to be called on closing the dialog (typically set dialog_open to `false`)
     */
    onClose: PropTypes.func.isRequired,
    /**
     * The property which triggers the opening of the dialog. Set this to true to open the dialog
     */
    dialog_open: PropTypes.bool.isRequired
}

DialogPDFViewer.defaultProps = {
    dialog_open: false
}

export default DialogPDFViewer;


