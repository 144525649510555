import React, {useContext} from "react";
import styled from 'styled-components';
import {Button} from "@material-ui/core";
import {ApplicantContext} from "../../ApplicantContext";

const ProcessButton = ({rowData}) => {
    const applicantContext = useContext(ApplicantContext);
    const {setActiveDocument} = applicantContext;

    return (
        <Button
            variant={"outlined"}
            onClick={() => {
                setActiveDocument(rowData.tableData.id)
            }}
            color={"primary"}>
            {!rowData.is_verified ? "Validate" : "View"}
        </Button>
    );
}

export const documentReviewColumns = [
    {
        field: 'document_type',
        title: 'Self Service Document',
        render: rowData => <GeneralTextDiv>{rowData.document_type}</GeneralTextDiv>

    },
    {
        field: 'is_verified',
        title: 'Status',
        render: rowData => <GeneralTextDiv>{!rowData.is_verified ? "Awaiting Review" : (rowData.is_valid ? "Valid" : "Invalid")}</GeneralTextDiv>
    },
    {
        field: 'rejection_reason',
        title: 'Rejection Reason',
        render: rowData => <GeneralTextDiv>{rowData.rejection_reason}</GeneralTextDiv>
    },
    {
        field: 'actions',
        title: 'Action',
        filtering: false,
        render: rowData => <ProcessButton rowData={rowData}/>
    }
]


const GeneralTextDiv = styled.div`
  color: #6B6B6B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`;