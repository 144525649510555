import React, {useCallback, useContext, useEffect, useState} from 'react';
import BackButton from "../../components/TshiamisoComponents/BackButton";
import {ApplicantContext} from "../../components/ApplicantContext";
import styled from "styled-components";
import {ReactComponent as CardIcon} from "./Card.svg";
import {TextareaAutosize} from "@material-ui/core";
import {StyledButton} from "../../components/TshiamisoComponents/Button";
import Banner from "../../components/Banner/Banner";
import ButtonGroup from "../../components/TshiamisoComponents/ButtonGroup";

const FeedbackWindow = () => {
    const applicantContext = useContext(ApplicantContext);
    const {
        applicant,
        setApplicantStatus,
        setShowFeedbackWindow,
        setApplicantStatusPayload,
        applicantStatusPayload,
    } = applicantContext;

    const [textField, setTextField] = useState("")

    useEffect(()=>{
        if (applicantStatusPayload.messageToApplicant)
            setTextField(applicantStatusPayload.messageToApplicant)
    },[])

    const setField = useCallback((field, value) => {
        setApplicantStatusPayload(prevState => {
            return {
                ...prevState,
                [field]: value
            }
        })
    }, [setApplicantStatusPayload]);

    const renderRequestDocument = (documentType) => {
        let selected = applicantStatusPayload.resubmitDocuments[documentType];
        return (
            <RequestDocumentsContainer>
                <div>Request Resubmission of documents?</div>
                <br/>
                <RequestDocumentsToggle>
                    <ButtonGroup
                        options={[
                            {value: 'yes', label: "Yes"},
                            {value: 'no', label: "No"}]}
                        value={selected ? "yes" : "no"}
                        onClick={(newValue) => {
                            setField("resubmitDocuments", {
                                ...applicantStatusPayload.resubmitDocuments,
                                [documentType]: newValue === 'yes'
                            })
                        }}
                    />
                </RequestDocumentsToggle>
            </RequestDocumentsContainer>
        )
    }

    const getDocumentsByDocumentType = (documentType) => {
        return applicant.documents.filter((document) => {
            return document['document_type'] === documentType
        })
    }

    const renderDocumentLabel = (document, index) => {
        if (document.is_valid) return null;
        let type = document.is_valid ? "success" : "warning"
        let messages = [];
        messages.push({title: "Document No", text: (index + 1).toString()})
        messages.push({title: "Rejection Reason", text: document.rejection_reason})

        return (
            <BannerContainer key={index}>
                <Banner
                    type={type}
                    messages={messages}
                />
            </BannerContainer>

        )
    }

    const renderDocumentStatuses = (document_type) => {
        let documents = getDocumentsByDocumentType(document_type)
        if (documents.length < 1)
            return (
                <BannerContainer>
                    <Banner
                        type={"info"}
                        clearBackground={true}
                        messages={[{title: "Status", text: "No documents provided"}]}
                    />
                    <br/>
                </BannerContainer>
            );

        if (documents.every((x) => x.is_valid))
            return (
                <BannerContainer>
                    <Banner
                        type={"success"}
                        clearBackground={true}
                        messages={[{title: "Status", text: "All documents are valid"}]}
                    />
                    <br/>
                </BannerContainer>
            );

        return documents.map((document, index) => renderDocumentLabel(document, index))
    }

    const renderDocumentSection = (title, document_type) => {
        return (
            <React.Fragment>
                <DocumentTitleDiv>
                    <CardIcon/>
                    <DocumentTitle>{title}</DocumentTitle>
                </DocumentTitleDiv>
                {renderDocumentStatuses(document_type)}
                {renderRequestDocument(document_type, title)}
            </React.Fragment>

        );
    }

    const canSubmit = () => {
        return !!applicantStatusPayload.messageToApplicant;

    };

    const onNextClick = () => {
        setApplicantStatus(applicantStatusPayload)
    };

    const getDocumentsToResubmit = () => {
        let count = 0;
        let text = ""
        Object.keys(applicantStatusPayload.resubmitDocuments).forEach((key, index) => {
            if (applicantStatusPayload.resubmitDocuments[key]) {
                if (count > 0)
                    text += `, ${key}`
                else
                    text += `${key}`
                count++;
            }
        })
        if (count > 0)
            text += '. '
        return text
    };

    return (
        <Container>
            <BackButton text={"Outstanding Requirements"} onClick={() => {
                setShowFeedbackWindow(false)
            }}/>
            <OutstandingRequirementsTitle>
                Please review the outstanding requirements below and send them to the applicant
            </OutstandingRequirementsTitle>
            {renderDocumentSection("IDENTIFICATION DOCUMENTS", "Identification Document")}
            {renderDocumentSection("SERVICE RECORDS", "Service Record")}
            {renderDocumentSection("MEDICAL RECORDS", "Medical Record")}
            {applicant.is_alive === 'No' && renderDocumentSection("CLAIMANT IDENTIFICATION DOCUMENTS", "Claimant Identification Document")}
            <br/>

            <DocumentTitleDiv>
                <CardIcon/>
                <DocumentTitle>Message to {applicant.is_alive === "Yes" ? "Applicant" : "Claimant"}</DocumentTitle>
            </DocumentTitleDiv>

            <FeedbackWindowContainer>
                <StyledTextInput
                    placeholder={"Type any further explanation here..."}
                    value={textField}
                    variant={"outlined"}
                    rowsMin={10}
                    onChange={(event) => {
                        setTextField(event.target.value)
                        setField('messageToApplicant', event.target.value)
                    }}
                />

                <MessagePreviewDiv>
                    <MessagePreviewTitleDiv>
                        Message Preview
                    </MessagePreviewTitleDiv>
                    <PreviewPane>
                        <h3>
                            To Claim from the Tshiamiso Trust is Free
                        </h3>
                        <h3>
                            Good
                            day {applicant.is_alive === "Yes" ? `${applicant.first_name} ${applicant.last_name}` : `${applicant.claimant.first_name} ${applicant.claimant.last_name}`}
                        </h3>
                        <p>
                            There is an important announcement from the Tshiamiso Trust regarding your application. The
                            following information is required &nbsp;
                            {getDocumentsToResubmit()}
                            {`${textField}`}
                        </p>
                        <br/>
                        <p>
                            To upload the outstanding documents, please reply *Yes*
                        </p>


                    </PreviewPane>


                </MessagePreviewDiv>
            </FeedbackWindowContainer>


            <br/>
            <StyledButton
                style={{marginLeft: "50px"}}
                smalltext={"true"}
                color={"primary"}
                variant={"contained"}
                disabled={!canSubmit()}
                onClick={onNextClick}>
                Save and Send
            </StyledButton>
            <br/>
        </Container>
    );
};

export default FeedbackWindow;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`
const FeedbackWindowContainer = styled.div`
  width: 100%;
  display: flex;
  grid-gap: 20px;
  padding: 0 50px;
`;

const OutstandingRequirementsTitle = styled.div`
  height: 24px;
  opacity: 0.8;
  color: #6B6B6B;
  font-size: 18px;
  letter-spacing: -0.28px;
  line-height: 24px;
  margin: 20px;
`

const DocumentTitleDiv = styled.div`
  display: grid;
  grid-template-columns: 40px 280px auto;
  align-items: center;
  justify-content: flex-start;
  margin: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;

`;

const DocumentTitle = styled.div`
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  margin: 15px;
`;

const MessagePreviewDiv = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 2px solid #F3F5FA;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const MessagePreviewTitleDiv = styled.div`
  width: 100%;
  height: 47px;
  border-radius: 8px 8px 0 0;
  background-color: #F7F9F2;
  display: flex;
  flex-direction: column;
  padding-left: 28px;
  align-items: flex-start;
  justify-content: center;
`;

const StyledTextInput = styled(TextareaAutosize)`
  min-width: 50%;
  border-radius: 10px;
  border: 2px solid #DFDFDF;
  color: #6B6B6B;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 20px;
  height: 30%;
`;

const PreviewPane = styled.div`
  padding: 20px;
`;

const BannerContainer = styled.div`
  width: 35%;
  margin-left: 50px;
  min-width: 520px;
  margin-bottom: 20px;
`;

const RequestDocumentsContainer = styled.div`
    padding: 0 50px;
`;

const RequestDocumentsToggle = styled.div`
`;