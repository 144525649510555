import React, {useContext, useEffect, useState} from 'react';
import {StyledButton} from "../../TshiamisoComponents/Button";
import styled from "styled-components";
import CustomizedTable from "../../table";
import {documentReviewColumns} from "./documentReviewColumns";
import {ApplicantContext} from "../../ApplicantContext";

const DocumentReviewTable = ({goBack, complete}) => {
    const applicantContext = useContext(ApplicantContext);
    const {applicant} = applicantContext;
    const {documents} = applicant || {};
    const [documentsValidated, setDocumentsValidated] = useState(false)

    useEffect(() => {
        if (!documents) return setDocumentsValidated(true);

        let documents_validated = true;
        documents.forEach((document) => {
            if (!document.is_verified) documents_validated = false
        })
        setDocumentsValidated(documents_validated)
    }, [documents])

    let table_props = {
        enableFiltering: false,
        title: "Case History",
        manuallyInjectedData: documents,
        enableSearch: false,
        disableToolbar: true
    }
    let table_config = {}



    return (
        <Container>
            <CustomizedTable
                config={table_config}
                props={table_props}
                columns={documentReviewColumns}
            />

            <br/>

            <StyledButton
                color={"primary"}
                variant={"contained"}
                disabled={!documentsValidated}
                onClick={complete}>NEXT</StyledButton>
        </Container>
    );
};

export default DocumentReviewTable;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 50px;
`;