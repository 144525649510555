import React, {useContext} from 'react';
import {StyledButton} from "../../TshiamisoComponents/Button";
import styled from "styled-components";
import CustomizedTable from "../../table";
import {caseHistoryColumns} from "./caseHistoryColumns";
import {ApplicantContext} from "../../ApplicantContext";

const CaseHistory = ({goBack, complete}) => {
    const applicantContext = useContext(ApplicantContext);

    let table_props = {
        enableFiltering: false,
        title: "Case History",
        manuallyInjectedData: applicantContext.applicantHistory,
        enableSearch: false,
        disableToolbar: true
    }
    let table_config = {}

    return (
        <Container>
            <CustomizedTable
                config={table_config}
                props={table_props}
                columns={caseHistoryColumns}
            />

            <br/>

            <StyledButton
                color={"primary"}
                variant={"contained"}
                onClick={complete}>NEXT</StyledButton>
        </Container>
    );
};

export default CaseHistory;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 50px;
`;