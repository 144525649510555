import React, {useState} from 'react';
import Select from "../../TshiamisoComponents/Select";

const CaseNextStatus = ({onChange}) => {
    const [value, setValue] = useState("");

    const options = [
        // {label: "Close - Send to CCMS for booking", value: "Submitted To CCMS for booking"},
        // {label: "Close - Send to CCMS for review", value: "Submitted To CCMS for review"},
        {label: "Close - Booking manually created on CCMS", value: "Booking Manually Created on CCMS"},
        {label: "Close - Manually refer to back office on CCMS", value: "Manually Refer To Back Office on CCMS"},
        {label: "Follow-up - More information required", value: "Additional Information Required"},
        {label: "Follow-up (Snooze for 3 days)", value: "Follow Up"},
        {label: "Close - Not eligible", value: "Closed - Not Eligible"}
    ]

    const onUpdate = (newValue) => {
        setValue(newValue)
        onChange && onChange(newValue)
    }

    return (
        <Select
            label={"Set Applicant Status"}
            id={"next_status"}
            options={options}
            value={value}
            onChange={onUpdate}
        />
    );
};

export default CaseNextStatus;