import React, {useContext, useEffect} from 'react';
import {useParams} from "react-router-dom";
import UserHeader from "../../components/UserHeader/UserHeader";
import styled from 'styled-components';
import ApplicantDetails from "../../components/ApplicantDetails/ApplicantDetails";
import CaseReviewSteps from "../../components/CaseReviewSteps/CaseReviewSteps";
import {ApplicantContext} from "../../components/ApplicantContext";
import DocumentReview from "./DocumentReview";
import FeedbackWindow from "./FeedbackWindow";
import ClaimantDetails from "../../components/ApplicantDetails/ClaimantDetails";


const CaseView = (props) => {
    const applicantContext = useContext(ApplicantContext);
    let {reference} = useParams();

    const {
        applicant,
        activeDocument,
        showFeedbackWindow,
        setApplicantReference,
        setApplicant,
        resetApplicantStatusPayload
    } = applicantContext;

    useEffect(() => {
        resetApplicantStatusPayload()
        setApplicantReference(reference);
        return () => {
            resetApplicantStatusPayload();
            setApplicantReference(null);
            setApplicant(null);
        }
    }, [reference, setApplicantReference, setApplicant]);

    const renderMainComponent = () => {
        if (activeDocument !== null)
            return <DocumentReview/>;

        if (showFeedbackWindow)
            return <FeedbackWindow/>

        return (
            <React.Fragment>
                <UserHeader
                    name={`${applicant.first_name} ${applicant.last_name}`}
                    id={applicant.identification_number}
                    reference_number={reference}/>
                <ApplicantDetails applicant={applicant}/>
                {
                    applicant.is_alive === 'No' &&
                    <ClaimantDetails claimant={{...applicant.claimant, language: applicant.language}}/>
                }
            </React.Fragment>
        )
    };

    const shouldHideSteps = () => {
        return activeDocument !== null || showFeedbackWindow;
    }
    if (!applicant)
        return (
            <Container>
                Loading...
            </Container>
        );

    return (
        <Container>
            {renderMainComponent()}
            <CaseReviewSteps hidden={shouldHideSteps()}/>
        </Container>
    );
};

export default CaseView;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;