import React from 'react';
import {useTheme} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import styled from "styled-components";
import makeStyles from "@material-ui/core/styles/makeStyles";

const usePaginationActionStyles = makeStyles((theme) => ({
    root: {
        float: "right",
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
}));

const Paginator = (props) => {
    const classes = usePaginationActionStyles();
    const theme = useTheme();
    const {count, page, rowsPerPage, onChangePage} = props;

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleIndexClick = (event, index) => {
        onChangePage(event, index);
    };
    return (
        <div className={classes.root}>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            {
                Array.from(Array(Math.ceil(Math.max(count / rowsPerPage, 1)))).map((item, index) => {
                    return (
                        <PageIndex key={index} onClick={(event) => handleIndexClick(event, index)}
                                    disabled={page === index} selected={page === index}>
                                {index + 1}
                        </PageIndex>
                    )
                })
            }
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
        </div>
    );
};

export default Paginator;


const PageIndex = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: ${(props)=> props.selected ? "bold" : "normal"};
  letter-spacing: -0.18px;
  line-height: 19px;
  margin: 0 5px;
  color: ${(props)=> props.selected ? "#327954" : "DFDFDF"};
`;