import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Facet, SearchProvider, WithSearch} from "@elastic/react-search-ui";
import {MultiCheckboxFacet} from "@elastic/react-search-ui-views";
import TextField from "@material-ui/core/TextField";
import SearchConnector from "./SearchConnector";
import ResultsTable from "./ResultsTable";

import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./index.css";

const connector = new SearchConnector()

const AdvancedSearch = ({config, props}) => {
    const classes = useStyles();

    return (
        <SearchProvider config={{
            apiConnector: connector,
            alwaysSearchOnInitialLoad: true,
            searchQuery: {
                facets: props.facets.reduce((acc, facet) => {
                    acc[facet] = {type: "value"}
                    return acc;
                }, {})
            }
        }}>
            <WithSearch
                mapContextToProps={({searchTerm, setSearchTerm, results}) => ({
                    searchTerm,
                    setSearchTerm,
                    results
                })}
            >
                {({searchTerm, setSearchTerm, results}) => {

                    return (
                        <div className={classes.container}>
                            <div className={classes.searchBox}>
                                <TextField
                                    fullWidth
                                    label="Search for applicant name, contact number or country"
                                    variant="outlined"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value, {debounce: 500})}/>
                            </div>

                            <div>
                                {props.facets.map(facet => (
                                    <Facet key={facet} field={facet} label={facet.replace("_", " ")} view={MultiCheckboxFacet}/>))}
                            </div>

                            <div className={classes.results}>
                                <ResultsTable config={{...config}} props={{...props}} results={results}/>
                            </div>
                        </div>
                    );
                }}
            </WithSearch>
        </SearchProvider>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "grid",
        gridTemplateColumns: "300px auto",
        gap: `${theme.spacing(4)}px`
    },
    searchBox: {
        gridColumnStart: 1,
        gridColumnEnd: 3,
        display: "flex"
    }
}));

export default AdvancedSearch;