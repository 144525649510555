import React, {useContext} from 'react';
import BackButton from "../../components/TshiamisoComponents/BackButton";
import {ApplicantContext} from "../../components/ApplicantContext";
import styled from "styled-components";
import DocumentPreviewContainer from "../../components/DocumentPreviewContainer/DocumentPreviewContainer";
import ReviewPanel from "../../components/ReviewPanel/ReviewPanel";

const DocumentReview = () => {
    const applicantContext = useContext(ApplicantContext);
    const {setActiveDocument} = applicantContext;

    return (
        <Container>
            <BackButton text={"Document Review"} onClick={() => {
                setActiveDocument(null)
            }}/>
            <DocumentReviewContainer>
                <DocumentPreviewContainer/>
                <ActionsContainer>
                    <ReviewPanel/>
                </ActionsContainer>
            </DocumentReviewContainer>
        </Container>
    );
};

export default DocumentReview;

const Container = styled.div`
  width: 100%;
  height: 100%;
`
const DocumentReviewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

const ActionsContainer = styled.div`
  width: 100%;
  border: 2px solid #F3F5FA;
  border-radius: 10px;
  background-color: #FFFFFF;
  height: fit-content;
  padding: 40px;
  min-height: 50%;
`

