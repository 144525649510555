import React, {createElement, useState} from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import CheckedIcon from './CheckedIcon'
import {ReactComponent as UpArrow} from './uparrow.svg';

function StepAccordion({ steps, backEnabled, reOpenLastStepOnClick, allowClickToOpen, disableClickAhead }) {
  const [stepIndex, setStepIndex] = useState(0)

  const completeStep = (callingIndex) => {
    if (callingIndex === stepIndex) {
      setStepIndex(stepIndex + 1)
    }
  }

  const goBack = (callingIndex) => {
    if (!backEnabled) return;
    if (callingIndex === stepIndex && stepIndex !== 0) {
      setStepIndex(stepIndex - 1)
    }
  }

  return (
    <div>
      {
        steps.map(
          (step, idx) =>
            <Accordion key={idx} expanded={stepIndex === idx}>
              <AccordionSummary
                expandIcon={<UpArrow />}
                onClick={() => {
                  if (allowClickToOpen) {
                    if (idx > stepIndex && disableClickAhead) return;
                    setStepIndex(idx);
                    return;
                  }
                  if (!reOpenLastStepOnClick) return;
                  if (stepIndex + 1 <= steps.length) return;
                  if (idx + 1 === steps.length) setStepIndex(stepIndex - 1)
                }}
              >
                <CheckedIcon checked={stepIndex > idx} number={step.title_idx} title={step.title} />
              </AccordionSummary>
              <AccordionDetails>
                {createElement(
                  step.component,
                  {...(step.props || {}), complete: () => completeStep(idx), goBack: () => goBack(idx)}
                )
                }
              </AccordionDetails>
            </Accordion>
        )
      }
    </div>
  )
}

StepAccordion.defaultProps = {
  backEnabled: false,
  reOpenLastStepOnClick: false,
  allowClickToOpen: false,
  disableClickAhead: false,
}

export default StepAccordion