import React, {useEffect} from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import styled from "styled-components";
import theme from "../../theme";

const ButtonGroup = ({options, value, onClick}) => {
    const [selected, setSelected] = React.useState(null);

    useEffect(() => {
        setSelected(value)
    }, [])

    const handleSelection = (event, newSelection) => {
        setSelected(newSelection);
        onClick && onClick(newSelection)
    };

    return (
        <StyledToggleButtonGroup
            exclusive
            value={selected}
            style={{borderRadius: "10px"}}
            onChange={handleSelection}>
            {(options || []).map((option, index) => <StyledToggleButton key={index}
                value={option.value}>{option.label}</StyledToggleButton>)}
        </StyledToggleButtonGroup>
    );
};

export default ButtonGroup;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

const StyledToggleButton = styled(ToggleButton)`
  &.MuiToggleButton-root {
    border: 0;
    min-width: 140px;
    height: 45px;
    min-height: 45px;
    font-size: ${({smalltext}) => smalltext ? '14px' : '16px'};
    font-weight: 900;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;

    &:hover {
      color: #fff;
      background-color: #000d1a;
    }
  }

  &.Mui-selected {
    color: #fff;
    background-color: ${theme.palette.primary.main};
  }

`;