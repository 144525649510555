import React, {useContext, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import MetricCard from "./MetricCard";
import {ApplicantContext} from "../../components/ApplicantContext";

const MetricGroup = ({ config, props }) => {
    const classes = useStyles();
    const applicantContext = useContext(ApplicantContext);
    const {setApplicant} = applicantContext;

    useEffect(()=>{
        setApplicant(null);
    },[setApplicant])

    let cards = props?.metrics?.statuses || [];
    return (
        <div className={classes.container}>
            {
                cards.map((card, index) => {
                    return (
                        <MetricCard key={index} status={card}/>
                    )
                })
            }
        </div>
    )

}

const useStyles = makeStyles((theme) => ({
    container: {
        display: "grid",
        gridAutoFlow: "column",
        gap: `${theme.spacing(3)}px`
    }
}));

export default MetricGroup;