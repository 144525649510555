import styled from 'styled-components';
import {Button} from "@material-ui/core";

export const StyledButton = styled(Button)`
  height: 45px;
  min-height: 45px;
  width: 180px;
  border-radius: 10px;
  font-size: ${({smalltext}) => smalltext === "true" ? '14px' : '16px'};
  font-weight: 900;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
`