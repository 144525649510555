import React from 'react';
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as CheckIcon} from "./check.svg";
import CloseIcon from '@material-ui/icons/Close';

const RadioButton = ({text, type, selected, onClick, disabled, showIconWhenNotSelected=false}) => {
    return (
        <Container onClick={onClick} selected={selected} disabled={disabled}>
            <CheckBox selected={selected} type={type}>
                {type === "positive" && (selected || showIconWhenNotSelected) && <CheckIcon/>}
                {type === "negative" && (selected || showIconWhenNotSelected) && <CloseIcon style={{color: "#7A282C", fontSize: "medium"}}/>}

            </CheckBox>
            <Text>{text}</Text>
        </Container>
    );
};

export default RadioButton;

const Container = styled(IconButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  min-width: 80px;
  border: ${({selected}) => `1px solid ${selected ? "gray" : "#F3F5FA"}`};
  border-radius: 18.5px;
  background-color: #FFFFFF;
  justify-content: flex-start;
  padding: 3.5px !important;
  width: fit-content;
`

const CheckBox = styled.div`
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({selected, type}) =>
          `${selected ? "1.5" : "1"}px solid ${selected ? (type === "positive" ? "#327954" : "#7A282C") : "#F3F5FA"}`
  };
  background-color: #FFFFFF;
`;

const Text = styled.div`
  color: #6B6B6B;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  margin: 0 11.5px;
`;