import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';

const PdfDialog = (props) => (
	<Dialog
		onClose={props.handleClose}
		open={props.open}
		scroll="paper"
		fullWidth={true}
		maxWidth={false}
		fullScreen={true}
		PaperProps={{
			style: {
				backgroundColor: 'transparent',
				boxShadow: 'none',
			},
		}}>

		{props.content}

	</Dialog>
)

PdfDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.node,
	content: PropTypes.node,
	handleClose: PropTypes.func
}

export default PdfDialog;