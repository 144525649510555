import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MaterialSelect from '@material-ui/core/Select';
import {ReactComponent as DownArrow} from "./downArrow.svg";
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function Select({label, id, onChange, value, options = []}) {
    const classes = useStyles();

    const handleChange = (event) => {
        onChange && onChange(event.target.value);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink>
                {label}
            </InputLabel>
            <StyledSelect
                labelId={id}
                id={id}
                style={{width: "100%"}}
                value={value}
                onChange={handleChange}
                IconComponent={DownArrow}
                className={classes.selectEmpty}
            >
                {options.map((option, index) => <MenuItem key={index}
                                                          value={option.value}>{option.label}</MenuItem>)}
            </StyledSelect>
        </FormControl>
    );
}

const StyledSelect = styled(MaterialSelect)`
    & .MuiInputBase-input {
      font-size: 22px;
      line-height: 27px;
    }
`;