import React, {useCallback, useContext, useEffect} from 'react';
import {StyledButton} from "../../TshiamisoComponents/Button";
import styled from "styled-components";
import CaseNextStatus from "./CaseNextStatus";
import {ApplicantContext} from "../../ApplicantContext";
import TextInput from "../../TshiamisoComponents/TextInput";

const CaseStatus = () => {
    const applicantContext = useContext(ApplicantContext);
    const {
        applicant,
        setApplicantStatus,
        setShowFeedbackWindow,
        applicantStatusPayload,
        setApplicantStatusPayload,
        resetApplicantStatusPayload
    } = applicantContext;

    useEffect(() => {
        resetApplicantStatusPayload();
        applicant.documents.forEach((document) => {
            if (!document.is_valid) {
                setApplicantStatusPayload(prevState => {
                    return {
                        ...prevState,
                        'resubmitDocuments': {
                            ...prevState.resubmitDocuments,
                            [document.document_type]: true
                        }
                    }
                })
            }
        })
    }, [resetApplicantStatusPayload, setApplicantStatusPayload])


    const setField = useCallback((field, value) => {
        setApplicantStatusPayload(prevState => {
            return {
                ...prevState,
                [field]: value
            }
        })
    }, [setApplicantStatusPayload]);

    const canSubmit = () => {
        return !!applicantStatusPayload.next_status && applicantStatusPayload.next_status !== "";

    };

    const onNextClick = () => {
        if (applicantStatusPayload.next_status === "Additional Information Required") {
            setShowFeedbackWindow(true);
        } else {
            setApplicantStatus(applicantStatusPayload)
        }
    };

    return (
        <Container>
            <CaseNextStatus
                onChange={(value) => {
                    setField("next_status", value)
                }}
            />
            <br/>
            <TextInput
                label={"Agent Notes"}
                value={applicantStatusPayload.notes || ""}
                multiline={true}
                variant={"outlined"}
                rows={5}
                onChange={(value) => setField("notes", value)}
            />
            <br/>
            <StyledButton
                smalltext={applicantStatusPayload.next_status === "Additional Information Required" ? "true" : "false"}
                color={"primary"}
                variant={"contained"}
                disabled={!canSubmit()}
                onClick={onNextClick}>
                {applicantStatusPayload.next_status === "Additional Information Required" ? "Prepare message" : "Save"}
            </StyledButton>
        </Container>
    );
};

export default CaseStatus;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
`;