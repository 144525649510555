import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';
import MaterialTextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function TextInput({label, id, onChange, defaultValue, helperText, value, multiline, variant, rows}) {
    const classes = useStyles();
    const [textValue, setTextValue] = useState("");

    useEffect(() => {
        setTextValue(value)
    }, [])

    const handleChange = (event) => {
        setTextValue(event.target.value);
        onChange && onChange(event.target.value);
    };

    return (
        <FormControl className={classes.formControl}>
            <StyledTextField
                id={id}
                label={label}
                value={textValue || ""}
                variant={variant || "standard"}
                onChange={handleChange}
                helperText={helperText}
                defaultValue={defaultValue}
                multiline={multiline}
                rows={rows || 1}
            />
        </FormControl>
    );
}

const StyledTextField = styled(MaterialTextField)`
    & .MuiInputBase-input {
      font-size: 22px;
      line-height: 27px;
    }
`;