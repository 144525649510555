import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ApplicantContext} from "../ApplicantContext";
import DocumentType from "./DocumentType";
import ValidForClaims from "./ValidForClaims";
import {StyledButton} from "../TshiamisoComponents/Button";
import styled from "styled-components";
import VerifyIdentityFields from "./VerifyIdentityFields";
import RejectionReason from "./RejectionReason";

const APPLICANT_REVIEW_PAYLOAD = {
    document_type: null,
    first_name: null,
    last_name: null,
    identification_number: null,
    industry_number: null,
    nationality: null,
    valid_for_submission: null,
    rejection_reason: null
}

const ReviewPanel = ({is_claimant = false}) => {
    const applicantContext = useContext(ApplicantContext);
    const {applicant, activeDocument, setDocumentValidation} = applicantContext;
    const {documents} = applicant || {};

    const [applicantReviewPayload, setApplicantReviewPayload] = useState({...APPLICANT_REVIEW_PAYLOAD})

    const setField = useCallback((field, value) => {
        setApplicantReviewPayload(prevState => {
            return {
                ...prevState,
                [field]:value
            }
        })
    }, [setApplicantReviewPayload]);

    useEffect(() => {
        let valid_for_submission = documents[activeDocument].is_valid === undefined ? null :
            documents[activeDocument].is_valid ? "Yes" : "No";
        setField("document_type", documents[activeDocument].document_type);
        setField("valid_for_submission", valid_for_submission)
        setField("identification_number", null)
    }, [activeDocument])

    const canSubmit = () => {
        if (applicantReviewPayload.document_type === "Identification Document") {
            if (applicantReviewPayload.valid_for_submission !== "No"){
                if (!applicantReviewPayload.identification_number)
                    return false
            }
        }
        if (applicantReviewPayload.document_type === "Claimant Identification Document") {
            if (applicantReviewPayload.valid_for_submission !== "No"){
                if (!applicantReviewPayload.identification_number)
                    return false
            }
        }
        if (applicantReviewPayload.valid_for_submission === "No") {
            if (!applicantReviewPayload.rejection_reason) return false;
        }
        return applicantReviewPayload.valid_for_submission !== null;

    };

    const onSave = () => {
        setDocumentValidation({[applicantReviewPayload.document_type === "Claimant Identification Document" ? "claimant" : "miner"]: {
            ...applicantReviewPayload,
            document_index: activeDocument
        }})
    };

    const renderRejectionReason = () => {
        return (
            <RejectionReason
                initialValue={documents[activeDocument].rejection_reason}
                onChange={(value) => setField("rejection_reason", value)}
            />
        );
    };

    const renderIdentificationValidationFields = () => {
        if (applicantReviewPayload.document_type !== "Identification Document") return null;

        return (
            <React.Fragment>
                <VerifyIdentityFields
                    applicantReviewPayload={applicantReviewPayload}
                    setField={setField}
                    entity={applicant}
                />
                <Spacer/>
            </React.Fragment>
        )
    }

    const renderClaimantIdentificationValidationFields = () => {
        if (applicantReviewPayload.document_type !== "Claimant Identification Document") return null;

        return (
            <React.Fragment>
                <VerifyIdentityFields
                    applicantReviewPayload={applicantReviewPayload}
                    setField={setField}
                    entity={applicant.claimant}
                />
                <Spacer/>
            </React.Fragment>
        )
    }

    return (
        <Container>
            <DocumentType
                initialValue={documents[activeDocument].document_type}
                onChange={(value) => setField("document_type", value)}
            />
            <ValidForClaims
                onChange={(newValue) => {
                    setField("valid_for_submission", newValue)
                }}
            />
            <Spacer margin={"20px"}/>
            {applicantReviewPayload.valid_for_submission === "No" && renderRejectionReason()}
            {renderIdentificationValidationFields()}
            {renderClaimantIdentificationValidationFields()}
            <Spacer/>
            <StyledButton
                variant={"contained"}
                color={"primary"}
                disabled={!canSubmit()}
                onClick={() => onSave()}
            >
                Save
            </StyledButton>
        </Container>
    );
};

export default ReviewPanel;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Spacer = styled.div`
  margin: ${({margin}) => margin ? margin : "60px"};
`;