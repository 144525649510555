import React from 'react';
import styled from 'styled-components';
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";

const SummaryTitle = styled.div`
  color: #6B6B6B;
  font-size: 12px;
  margin-bottom: 3px;
`
const SummaryContent = styled.div`
  display: flex;
  flex-direction: row;
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
`

function SummaryCard({title, value, copy}) {
    return (
        <div>
            <SummaryTitle>{title}</SummaryTitle>
            <SummaryContent>
                {value}
                {value && copy && <CopyToClipboard text={value}/>}
            </SummaryContent>
        </div>
    )
};

export default SummaryCard;