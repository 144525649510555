import React from 'react';
import Summary from "./Summary";

const getDate = (dateString) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const dateObj = new Date(dateString);
    const month = monthNames[dateObj.getMonth()];
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day} ${month} ${year}`;

}

const ApplicantDetails = ({applicant}) => {
    let cards=[
        {
            "title": "Contact Number",
            "value": applicant.cellphone_number,
            "copy": true
        },
        {
            "title": "Add. Contact Number",
            "value": applicant.additional_contact_number,
            "copy": true
        },
        {
            "title": "Last Contact Date",
            "value": getDate(applicant.status_updated_at)
        },
        {
            "title": "Case Status",
            "value": applicant.status === "Review In Progress" ? `${applicant.previous_status} - In Review` : applicant.status
        },
        {
            "title": "Living Status",
            "value": applicant.is_alive === "Yes" ? "Alive" : "Passed Away"
        },
        {
            "title": "Country",
            "value": applicant.country
        },
        {
            "title": "Preferred Language",
            "value": applicant.language
        },
        {
            "title": "Industry Number",
            "value": applicant.industry_number,
            "copy": true
        }
    ];

    return (
        <Summary
            title={"MINE WORKER DETAILS"}
            cards={cards}
        />
    );
};

export default ApplicantDetails;