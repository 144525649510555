import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {ReactComponent as DownloadIcon} from "./download.svg";
import {ApplicantContext} from "../ApplicantContext";
import fileDownload from "js-file-download";
import DocumentPreviewPaginator from "./DocumentPreviewPaginator/DocumentPreviewPaginator";
import URI from 'urijs';
import {ReactComponent as ZoomIcon} from "./zoom.svg";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import IconButton from "@material-ui/core/IconButton";
import DialogPDFViewer from "../pdf_viewer/DialogPdfViewer";
import PDFViewer from "../pdf_viewer/pdf_viewer";

const DocumentPreviewContainer = () => {
    const applicantContext = useContext(ApplicantContext);
    const {applicant, activeDocument} = applicantContext;
    const {documents} = (applicant || {});
    const [currentFile, setCurrentFile] = useState(null);
    const [imageLightboxOpen, setImageLightboxOpen] = useState(false);
    const [pdfLightboxOpen, setPdfLightboxOpen] = useState(false);

    let url = documents[activeDocument].file_path || null;

    const downloadFile = () => {
        fetch(url)
            .then(res => res.blob())
            .then(blob => {
                fileDownload(blob, URI(url).filename())
            });
    }

    const blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    useEffect(() => {
        setCurrentFile(null);
        fetch(documents[activeDocument].file_path)
            .then(res => res.blob())
            .then(blobToBase64)
            .then(finalResult => {
                setCurrentFile(finalResult);
            });
    }, [activeDocument, documents])

    const renderPdf = () => {
        if (!currentFile) return <PdfContainer>Loading...</PdfContainer>;

        return (
            <PdfContainer>
                <PDFViewer file_pdf_url={currentFile}/>
            </PdfContainer>
        );
    };

    const renderImage = () => {
        if (!currentFile) return <PdfContainer>Loading...</PdfContainer>;
        return <ImageContainer src={currentFile} alt={"Loading..."}/>
    };

    const renderDocument = () => {
        if (URI(url).suffix() === "pdf")
            return renderPdf();
        return renderImage();
    };

    return (
        <Container>
            <DocumentPreview>
                {renderDocument()}

                <ZoomIconButton
                    onClick={() => {
                        if (URI(url).suffix() === "pdf") return setPdfLightboxOpen(true);
                        setImageLightboxOpen(true);
                    }}
                >
                    <ZoomIcon/>
                </ZoomIconButton>
            </DocumentPreview>

            <Footer>
                <DownloadDiv onClick={() => {
                    downloadFile()
                }}>
                    <DownloadIcon/>
                    <DownloadText>Download {URI(url).suffix()}</DownloadText>
                </DownloadDiv>
                <DocumentPreviewPaginator/>
            </Footer>

            {
                imageLightboxOpen &&
                <Lightbox image={currentFile} title="" onClose={() => {
                    setImageLightboxOpen(false)
                }}/>
            }
            {
                URI(url).suffix() === "pdf" &&
                    <DialogPDFViewer
                        dialog_open={pdfLightboxOpen}
                        file_url={currentFile}
                        onClose={()=>setPdfLightboxOpen(false)}
                    />
            }
        </Container>
    );
};

export default DocumentPreviewContainer;

const Container = styled.div`
  width: 100%;
  border: 2px solid #F3F5FA;
  border-radius: 10px;
  background-color: #FFFFFF;
  min-height: 50%;
  height: 50%;
  overflow-y: auto;
  display: grid;
  grid-template-rows: auto 59px;
`

const DocumentPreview = styled.div`
  position: relative;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

const PdfContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

const ImageContainer = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
`;

const Footer = styled.div`
  background-color: #70B043;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const DownloadDiv = styled.div`
  display: flex;
  flex-direction: row;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
`;

const DownloadText = styled.div`
  color: #ffffff;
  margin-left: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
`;

const ZoomIconButton = styled(IconButton)`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
