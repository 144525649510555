import React, {useContext, useEffect, useState} from 'react';
import Select from "../TshiamisoComponents/Select";
import {ApplicantContext} from "../ApplicantContext";

const DocumentType = ({initialValue, onChange}) => {
    const applicantContext = useContext(ApplicantContext);
    const {
        applicant,
    } = applicantContext;

    const [value, setValue] = useState(initialValue);
    const options = applicant.is_alive === 'No' ? [
        {label: "Identification Document", value: "Identification Document"},
        {label: "Service Record", value: "Service Record"},
        {label: "Medical Record", value: "Medical Record"},
        {label: "Claimant Identification Document", value: "Claimant Identification Document"}
    ] : [
        {label: "Identification Document", value: "Identification Document"},
        {label: "Service Record", value: "Service Record"},
        {label: "Medical Record", value: "Medical Record"}
    ]


    const onUpdate = (newValue) => {
        setValue(newValue)
        onChange && onChange(newValue)
    };

    useEffect(() => {
        onUpdate(initialValue)
    }, [initialValue])

    return (
        <Select
            label={"Document Type"}
            id={"document_type"}
            options={options}
            value={value}
            onChange={onUpdate}
        />
    );
};

export default DocumentType;