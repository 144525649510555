import React from 'react';
import StepAccordion from "../StepAccordian/StepAccordion";
import CaseHistory from "./CaseHistory/CaseHistory";
import DocumentReviewTable from "./DocumentReviewTable/DocumentReviewTable";
import CaseStatus from "./CaseStatus/CaseStatus";
import styled from 'styled-components';

const CaseReviewSteps = ({hidden}) => {
    const steps = [
        {
            title: "CASE HISTORY",
            title_idx: "01",
            component: CaseHistory,
        },
        {
            title: "DOCUMENT REVIEW",
            title_idx: "02",
            component: DocumentReviewTable,
        },
        {
            title: "CASE STATUS",
            title_idx: "03",
            component: CaseStatus,
        }
    ]

    return (
        <AccordianContainer hidden={hidden}>
            <StepAccordion
                allowClickToOpen={true}
                disableClickAhead={true}
                steps={steps}
            />
        </AccordianContainer>
    );

};

export default CaseReviewSteps;

const AccordianContainer = styled.div`
  width: 100%;
  height: 100%;
`;