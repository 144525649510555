import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import RadioButton from "../TshiamisoComponents/RadioButton";

const ApprovalRadio = ({initialValue, onChange, refresh}) => {
    const [selected, setSelected] = useState(initialValue);

    const setValue = (value) =>{
        setSelected(value);
        onChange && onChange(value)
    }

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue, refresh]);

    return (
        <Container>
            <RadioButton
                text={"Yes"}
                type={"positive"}
                selected={selected === "Yes"}
                onClick={()=>setValue("Yes")}
            />
            <Spacer/>
            <RadioButton
                text={"No"}
                type={"negative"}
                selected={selected === "No"}
                onClick={()=>setValue("No")}
            />
        </Container>
    );
};

export default ApprovalRadio;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Spacer = styled.div`
  margin: 3px;
`;

