import React from 'react';
import Summary from "./Summary";


const ClaimantDetails = ({claimant}) => {

    let cards=[
        {
            "title": "Full Name",
            "value": `${claimant.first_name} ${claimant.last_name}`,
            "copy": true
        },
        {
            "title": "Contact Number",
            "value": claimant.cellphone_number,
            "copy": true
        },
        {
            "title": "Identification Number",
            "value": claimant.identification_number
        },
        {
            "title": "Preferred Language",
            "value": claimant.language
        }
    ];

    return (
        <Summary
            title={"CLAIMANT DETAILS"}
            cards={cards}
        />
    );
};

export default ClaimantDetails;