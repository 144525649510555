import React, {useEffect, useState} from 'react';
import Select from "../TshiamisoComponents/Select";

const Country = ({initialValue, onChange}) => {

    const [value, setValue] = useState(initialValue);
    const options = [
        {label: "South Africa", value: "South Africa"},
        {label: "Mozambique", value: "Mozambique"},
        {label: "Swaziland", value: "Swaziland"},
        {label: "Lesotho", value: "Lesotho"},
        {label: "Botswana", value: "Botswana"},
        {label: "Malawi", value: "Malawi"},
        {label: "Tanzania", value: "Tanzania"},
        {label: "Zambia", value: "Zambia"},
        {label: "Zimbabwe", value: "Zimbabwe"},
    ]

    const onUpdate = (newValue) => {
        setValue(newValue)
        onChange && onChange(newValue)
    }

    useEffect(() => {
        onUpdate(initialValue)
    }, [initialValue])

    return (
        <Select
            label={"Country"}
            id={"nationality"}
            options={options}
            value={value}
            onChange={onUpdate}
        />
    );
};

export default Country;