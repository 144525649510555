import React from 'react';
import CustomizedTable from "../../components/table";
import {newUpdatedCasesColumns} from "../Table/newUpdatedCasesColumns";


const ResultsTable = (props) => {
    let table_props = {
        title: "Search Results",
        manuallyInjectedData: props.results,
        enableSearch: false,
        enableFiltering: false
    }
    let table_config = {}

    return (
        <CustomizedTable
            config={table_config}
            props={table_props}
            columns={newUpdatedCasesColumns}
        />
    )
}


export default ResultsTable;