import React from 'react';
import styled from 'styled-components'
import {ReactComponent as Tick} from './tick.svg';

const CircleFrame = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    margin-right: 10px;
`

const CircleUnchecked = styled(CircleFrame)`
    border: 0.8px solid #A8CE38;
    color: #A8CE38;
`

const CircleChecked = styled(CircleFrame)`
    background-color: #A8CE38;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Frame = styled.div`
    display: flex;
    align-items: center;
    font-size:16px;
`


export default function CheckedIcon({number, checked, title}) {
    let circle
    if (checked) {
        circle = (<CircleChecked><Tick /></CircleChecked>)
    } else {
        circle = (<CircleUnchecked>{number}</CircleUnchecked>)
    }
    return (
        <Frame>{circle}<div>{title}</div></Frame>
    )
}