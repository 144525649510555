import React, {useContext} from "react";
import styled from 'styled-components';
import {Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {ApplicantContext} from "../../components/ApplicantContext";
import {AppConfigContext} from "../../App";

const ProcessButton = ({rowData}) => {
    const history = useHistory()
    const applicantContext = useContext(ApplicantContext);
    const appConfig = useContext(AppConfigContext);

    return (
        <Button
            variant={"outlined"}
            onClick={() => {
                if (["Submission Updated", "New Case"].indexOf(rowData.status) > -1){
                    applicantContext.reserveCase(rowData.reference_number).then(r => {
                        history.push(`/case/${rowData.reference_number}`)
                    }).catch((e) => {
                        console.log(e?.response?.data)
                        appConfig.errorSnack("An error has occurred ", e?.response?.data)
                    })
                }
                else {
                    history.push(`/case/${rowData.reference_number}`)
                }

            }}
            color={"primary"}>
            Process
        </Button>
    );
}

export const newUpdatedCasesColumns = [
    {
        field: 'first_name',
        title: 'Applicant Name & Country',
        filtering: false,
        headerStyle: {
            width: '60px'
        },
        render: (rowData) =>
            <NameNationality>
                <Name>{`${rowData.first_name} ${rowData.last_name}`}</Name>
                <Nationality>{rowData.country}</Nationality>
            </NameNationality>,
        customFilterAndSearch: (term, rowData) => {
            let termInName = rowData.first_name.toLowerCase().includes(term.toLowerCase())
            let termInSurname = rowData.last_name.toLowerCase().includes(term.toLowerCase())
            let termInCountry = rowData.country.toLowerCase().includes(term.toLowerCase())
            return termInSurname || termInName || termInCountry
        },
    },
    {
        field: 'cellphone_number',
        title: 'Contact No.',
        filtering: false,
        render: rowData => <GeneralTextDiv>{rowData.cellphone_number}</GeneralTextDiv>
    },
    {
        field: 'status_updated_at',
        title: 'Contact Date & Status',
        filtering: false,
        render: rowData =>
            <DateStatus>
                <DateDiv>{new Date(rowData.status_updated_at).toLocaleDateString()}</DateDiv>
                <Status>{rowData.status}</Status>
            </DateStatus>,
        customFilterAndSearch: (term, rowData) => {
            let termInStatus = rowData.status.toLowerCase().includes(term.toLowerCase())
            let termInDate = new Date(rowData.status_updated_at).toLocaleDateString().includes(term.toLowerCase())
            return termInStatus || termInDate
        },
        customSort: (a, b) => (new Date(a.status_updated_at)) > (new Date(b.status_updated_at)) ? -1 : 1,
    },
    {
        field: 'language',
        title: 'Pref. Language',
        lookup: {"English": 'English', "isiZulu": 'isiZulu'},
        filtering: false,
        render: rowData => <GeneralTextDiv>{rowData.language}</GeneralTextDiv>
    },
    {
        field: 'actions',
        title: 'Action',
        filtering: false,
        render: rowData => <ProcessButton rowData={rowData}/>
    }
]

const NameNationality = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Name = styled.div`
  color: #6B6B6B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: bold;
`;

const Nationality = styled.div`
  color: #6B6B6B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`;


const DateStatus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const DateDiv = styled.div`
  color: #6B6B6B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`;

const Status = styled.div`
  color: #6B6B6B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`;

const GeneralTextDiv = styled.div`
  color: #6B6B6B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`;