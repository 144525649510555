import React from 'react';
import {ReactComponent as LeftArrow} from "./leftarrow.svg";
import styled from "styled-components";

const BackButton = ({text, onClick}) => {
    return (
        <Container onClick={onClick}>
            <LeftArrow/>
            <BackText>{text || "Back"}</BackText>
        </Container>
    );
};

export default BackButton;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  margin: 20px 0;
  max-width: fit-content;
`

const BackText = styled.div`
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  margin-left: 10px;
`