import React from 'react';
import styled from "styled-components";

const Banner = ({type, clearBackground, messages = []}) => {
    let borderColor;
    let backgroundColor;
    let textColor;

    if (type === "info"){
        borderColor = "#C5D5ED"
        backgroundColor = "#D8EEF2"
        textColor = "#28507a"
    }
    else if (type === "success"){
        borderColor = "#d3edc5"
        backgroundColor = "#e0f2d8"
        textColor = "#287a2b"
    }
    else if (type === "warning"){
        borderColor = "#EDC5C8"
        backgroundColor = "#ffffff"
        textColor = "#7A282C"
    }

    if (clearBackground){
        backgroundColor = "#ffffff"
    }

    return (
        <Container borderColor={borderColor} backgroundColor={backgroundColor}>
            {messages.map((message, index) => {
                return (
                    <React.Fragment key={index}>
                        {index > 0 && <Divider color={textColor}/>}
                        <Message>
                            {message.title && <MessageTitle textColor={textColor}>{message.title}</MessageTitle>}
                            {message.text && <MessageText textColor={textColor}>{message.text}</MessageText>}
                        </Message>
                    </React.Fragment>
                );
            })}
        </Container>
    );
};

export default Banner;

const Container = styled.div`
  box-sizing: border-box;
  height: 40px;
  border: ${({borderColor})=>`1px solid ${borderColor}`};
  border-radius: 10px;
  background-color: ${({backgroundColor})=> backgroundColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 20px;
`

const Divider = styled.div`
  box-sizing: border-box;
  height: 20px;
  width: 1px;
  border: ${({color})=>`1px solid ${color}`};
  opacity: 0.5;
  margin: 0 20px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const MessageTitle = styled.div`
  color: ${({textColor})=> textColor};
  font-size: 14px;
  letter-spacing: -0.22px;
  line-height: 15px;
`;

const MessageText = styled.div`
  color: ${({textColor})=> textColor};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.22px;
  line-height: 15px;
  margin-left: 5px;
`;