import React from 'react';
import styled from 'styled-components'
import SummaryCard from './SummaryCard'

const Frame = styled.div`
    border: 1px solid #F3F5FA;
    border-radius: 10px;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(4, minmax(80px, 1fr));
    grid-gap: 1.3rem;
    margin: 10px;
`

const FrameTitle = styled.div`
    grid-column: 1 / -1;
    font-size: 16px;
`


function Summary({ title, cards }) {

    return (
        <Frame>
            <FrameTitle>{title}</FrameTitle>
            {cards.map((card,idx) => <SummaryCard key={idx} title={card.title} value={card.value} copy={card.copy}/>)}
        </Frame>
    )
}

export default Summary;
