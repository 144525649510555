import React, {Component} from 'react';
import styled from "styled-components";
import {Button} from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/core/es/internal/svg-icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/core/es/internal/svg-icons/KeyboardArrowRight";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CancelIcon from '@material-ui/icons/Cancel';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';

export default class PDFViewer extends Component {
    state = {
        numPages: null,
        pageNumber: 1,
        scale: 1
    };

    onDocumentLoadSuccess = (document) => {
        const { numPages } = document;
        this.setState({
            numPages,
            pageNumber: 1,
        });
    };

    changePage = offset => this.setState(prevState => ({
        pageNumber: prevState.pageNumber + offset,
    }));

    previousPage = () => this.changePage(-1);

    nextPage = () => this.changePage(1);

    render() {
        const { numPages, pageNumber } = this.state;

        return (
            <React.Fragment>
                    <DocumentContainer>
                        <HeaderContainer>

                            <ZoomContainer>
                                {this.state.scale <= 1 ? null :
                                    <Button
                                        style={{color:"White"}}
                                        onClick={() => {this.setState({scale: this.state.scale - 0.1})}}>
                                        <ZoomOutIcon fontSize={"large"}/>
                                    </Button>}
                                <Button
                                    style={{color:"White"}}
                                    onClick={() => {this.setState({scale: this.state.scale + 0.1})}}>
                                    <ZoomInIcon fontSize={"large"}/>
                                </Button>
                                {this.props.onClose &&
                                    <Button
                                        style={{color: "White"}}
                                        onClick={() => {
                                            this.props.onClose()
                                        }}>
                                        <CancelIcon fontSize={"large"}/>
                                    </Button>}
                            </ZoomContainer>

                        </HeaderContainer>

                        <NavLeftContainer>
                            <Button
                                style={{color:"white"}}
                                disabled={pageNumber <= 1}
                                onClick={this.previousPage}>
                                {pageNumber <= 1 ? null : <KeyboardArrowLeft fontSize={"large"} />}
                            </Button>
                        </NavLeftContainer>

                        <PdfContainer>
                            <Document
                                options={{
                                    cMapUrl: '../../../assets/cmaps/',
                                    cMapPacked: true,
                                }}
                                file={this.props.file_pdf_url}
                                // file={samplePDF}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                onError={(e)=>{console.log(e)}}
                            >
                                <Page
                                    pageNumber={pageNumber}
                                    scale={this.state.scale}
                                />
                            </Document>
                        </PdfContainer>

                        <NavRightContainer>
                            <Button
                                style={{color:"white"}}
                                disabled={pageNumber >= numPages}
                                onClick={this.nextPage}>
                                {pageNumber >= numPages ? null : <KeyboardArrowRight fontSize={"large"}/> }
                            </Button>
                        </NavRightContainer>
                        <FooterContainer>
                            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                        </FooterContainer>
                    </DocumentContainer>
            </React.Fragment>
        );
    }
}

const DocumentContainer = styled.div`
    color: white;
    display: grid;
    grid-template-columns: 60px 1fr 60px;
    grid-template-rows: 80px 1fr 80px;
    grid-template-areas: 'header header header'
                        'left-arrow pdf right-arrow'
                        'footer footer footer';
    align-items: center;
    overflow: hidden;
    border: none;
    
`;

const ZoomContainer = styled.div`
      float: right;
`;

const HeaderContainer = styled.div`
      grid-area: header;
      background-color: #000000bf;
      height: 100%;
      width: 100%;
      align-items: center;
      border: none;
      color: white;
`;

const FooterContainer = styled.div`
    grid-area: footer;
    background-color: #000000bf;
    color: white;
    height: 100%;
    border: none;
    align-items: center;
    text-align: -webkit-center;
`;

const NavLeftContainer = styled.div`
    grid-area: left-arrow;
    display: flex;
    align-items: center;
    background-color: #000000bf;
    color: white;
    height: 100%;
    width: 100%;
    border: none;
`;

const NavRightContainer = styled.div`
    grid-area: right-arrow;
    display: flex;
    align-items: center;
    background-color: #000000bf;
    color: white;
    height: 100%;
    width: 100%;
    border: none;
`;

const PdfContainer = styled.div`
    grid-area: pdf;
    text-align: -webkit-center;
    height: 80vh;
    overflow: auto;
    background-color: #000000bf;
    border: none;
`;