import React, {useContext} from 'react';
import styled from "styled-components";
import ApprovalRadio from "../ApprovalRadio/ApprovalRadio";
import {ApplicantContext} from "../ApplicantContext";

const ValidForClaims = ({onChange}) => {
    const applicantContext = useContext(ApplicantContext);
    const {applicant, activeDocument} = applicantContext;
    const {documents} = applicant || {};

    return (
        <Container>
            <Title>
                Is document valid for claims process?
            </Title>
            <ApprovalRadio
                initialValue={documents[activeDocument].is_valid === undefined ? null : documents[activeDocument].is_valid ? "Yes" : "No"}
                onChange={(value)=>onChange(value)}
                refresh={activeDocument}
            />
        </Container>
    );
};

export default ValidForClaims;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px;
`

const Title = styled.div`
  opacity: 0.8;
  color: #000000;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 20px 0;
`;