import React from "react";
import styled from 'styled-components';

export const caseHistoryColumns = [
    {
        field: 'date',
        title: 'Date',
        width: 120,
        render: rowData =>
            <DateStatus>
                <DateDiv>{new Date(rowData.timestamp).toLocaleDateString(
                  'en-za',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  }
                )}
                </DateDiv>
                <TimeDiv>
                    {new Date(rowData.timestamp).toLocaleTimeString()}
                </TimeDiv>
            </DateStatus>,
        customFilterAndSearch: (term, rowData) => {
            return new Date(rowData.timestamp).toLocaleDateString().includes(term.toLowerCase())
        },
        customSort: (a, b) => (new Date(a.timestamp)) > (new Date(b.timestamp)) ? -1 : 1,
    },
    {
        field: 'user',
        title: 'Agent',
        width: 120,
        render: rowData => <GeneralTextDiv>{rowData.user}</GeneralTextDiv>
    },
    {
        field: 'notes',
        title: 'Case Notes',
        render: rowData => <GeneralTextDiv>{rowData.notes}</GeneralTextDiv>
    }
]


const DateStatus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
`;

const DateDiv = styled.div`
  color: #6B6B6B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`;

const TimeDiv = styled(DateDiv)`
`;

const GeneralTextDiv = styled.div`
  color: #6B6B6B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`;