import React, {useContext, useEffect, useState} from 'react';
import Recycle from "../Recycle";
import {useHistory} from "react-router-dom";
import {AppConfigContext} from "../App";

export const ApplicantContext = React.createContext(null);

const APPLICANT_STATUS_PAYLOAD = {
    next_status: null,
    notes: null,
    resubmitDocuments: {
        "Identification Document": false,
        "Claimant Identification Document": false,
        "Service Record": false,
        "Medical Record": false
    },
    messageToApplicant: null
}

const ApplicantProvider = ({children}) => {
    const appConfig = useContext(AppConfigContext);
    const [applicantReference, setApplicantReference] = useState(null);
    const [applicant, setApplicant] = useState(null);
    const [applicantHistory, setApplicantHistory] = useState([]);
    const [activeDocument, setActiveDocument] = useState(null);
    const [showFeedbackWindow, setShowFeedbackWindow] = useState(false);
    const [applicantStatusPayload, setApplicantStatusPayload] = useState({...APPLICANT_STATUS_PAYLOAD});

    const history = useHistory();

    const resetApplicantStatusPayload = React.useCallback(() => {
        setActiveDocument(null);
        setShowFeedbackWindow(null);
        setApplicantStatusPayload({...APPLICANT_STATUS_PAYLOAD});
    }, []);

    useEffect(()=>{
        let documents = applicant?.documents || []

        const getDocumentsByDocumentType = (documentType) => {
            return documents.filter((document) => {
                return document['document_type'] === documentType
            })
        }

        let documentTypes = {...APPLICANT_STATUS_PAYLOAD.resubmitDocuments}
        Object.keys(documentTypes).forEach((documentType) => {
            let documents_for_type = getDocumentsByDocumentType(documentType);
            documents_for_type.forEach((document) => {
                if (!document.is_valid){
                    documentTypes[documentType] = true
                }
            })
        })
        setApplicantStatusPayload(prevState => {
            return {...prevState, resubmitDocuments: documentTypes}
        })

    }, [applicant?.documents])

    const reserveCase = React.useCallback(async (reference_number, showLoader = true) => {
        return Recycle.rpc({
            model: "AgentPortalController",
            method: "reserve_case",
            args: [reference_number]
        }, showLoader)
    }, []);

    const fetchApplicantHistory = React.useCallback((showLoader = true) => {
        if (!applicantReference) return;
        Recycle.rpc({
            model: "AgentPortalController",
            method: "get_case_history",
            args: [applicantReference]
        }, showLoader).then(setApplicantHistory).catch((e) => {
            appConfig.errorSnack("An error has occurred: ", e?.response?.data || "Unknown error");
        })
    }, [setApplicantHistory, applicantReference]);

    const fetchApplicant = React.useCallback((showLoader = true) => {
        if (!applicantReference) return;
        Recycle.rpc({
            model: "AgentPortalController",
            method: "get_miner_by_reference",
            args: [applicantReference, true]
        }, showLoader).then(setApplicant)
            .catch((e) => {
                appConfig.errorSnack("An error has occurred: ", e?.response?.data || "Unknown error");
            })
    }, [setApplicant, applicantReference]);

    const setDocumentValidation = React.useCallback((payload, showLoader = true) => {
        if (!applicantReference) return;
        Recycle.rpc({
            model: "AgentPortalController",
            method: "set_document_validation",
            args: [applicantReference, payload]
        }, showLoader).then((r) => {
            setApplicant(r);
            appConfig.successSnack("Saved!");
            if (activeDocument < applicant.documents.length - 1){
                setActiveDocument(activeDocument + 1)
            }
            else {
                setActiveDocument(null);
            }
        }).catch((e) => {
            appConfig.errorSnack("An error has occurred: ", e?.response?.data || "Unknown error");
        })
    }, [setApplicant, applicantReference, applicant, activeDocument]);

    const setApplicantStatus = React.useCallback((payload, showLoader = true) => {
        if (!applicantReference) return;
        Recycle.rpc({
            model: "AgentPortalController",
            method: "set_applicant_status",
            args: [applicantReference, payload]
        }, showLoader).then((r) => {
            if (r === 'Saved') {
                appConfig.successSnack("Saved!");
                history.push('/')
            }
        }).catch((e) => {
            appConfig.errorSnack("An error has occurred: ", e?.response?.data || "Unknown error");
        })
    }, [setApplicant, applicantReference]);

    useEffect(() => {
        fetchApplicant();
        fetchApplicantHistory();
    }, [applicantReference])

    return (
        <ApplicantContext.Provider value={{
            applicant,
            setApplicant,
            fetchApplicant,
            applicantReference,
            setApplicantReference,
            applicantHistory,
            activeDocument,
            setActiveDocument,
            setDocumentValidation,
            setApplicantStatus,
            reserveCase,
            showFeedbackWindow,
            setShowFeedbackWindow,
            applicantStatusPayload,
            setApplicantStatusPayload,
            resetApplicantStatusPayload
        }}>
            {children}
        </ApplicantContext.Provider>
    );
};

export default ApplicantProvider;