import Recycle from "../../Recycle";

class SearchConnector {
    onResultClick() { }

    onAutocompleteResultClick() { }

    onSearch(state, queryConfig) {
        const facetFilters = getFacetFilters(state.filters);
        const facetsDistribution = Object.keys(queryConfig.facets);

        const payload = {
            q: state.searchTerm,
            facetsDistribution,
            ...(facetFilters.length > 0 && { facetFilters })
        }

        return Recycle.rpc({
            model: "AgentPortalController",
            method: "search_cases",
            args: [payload]
        }, false).then((res) => {
            return responseAdapter(res, queryConfig);
        })
    }

    async onAutocomplete() { }
}

const responseAdapter = (response, queryConfig) => {
    const results = response.hits;
    const totalResults = response.nbHits;
    const facets = getFacets(response.facetsDistribution, queryConfig.facets);

    return {
        rawResponse: response,
        results,
        totalResults,
        facets
    }
}

const getFacets = (facetsDistribution, facetsConfig) => {
    const facets = {};

    for (const [facetName, facetValue] of Object.entries(facetsDistribution)) {
        const data = Object.entries(facetValue).map(([value, count]) => ({
            value, count
        }));

        const sorted = data.sort((a, b) => {
            return (a.value > b.value) ? 1 : -1
        });

        facets[facetName] = [{
            field: facetName,
            data: sorted,
            type: "value"
        }]
    }

    return facets;
}

const getFacetFilters = (filters) => {
    const facetFilters = [];

    filters.forEach(filter => {
        const filterName = filter.field;

        filter.values.forEach(value => {
            facetFilters.push(`${filterName}:${value}`)
        })
    });

    return facetFilters;
}

export default SearchConnector;