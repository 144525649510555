import React, {useContext} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import SideNavBar from './SideNavBar';
import Header from './Header';
import Widget from '../widgets';
import {AppConfigContext} from "../App";
import CaseView from "../views/CaseView/CaseView";
import ApplicantProvider from "../components/ApplicantContext";

const Page = React.memo(({config}) => {
    return React.createElement(React.memo(Widget), {config});
});

const Base = ({pages, menu}) => {
    const appConfig = useContext(AppConfigContext);
    const classes = useStyles();

    return (
        <Router>
            <ApplicantProvider>

                <div className={classes.root}>
                    <Header/>

                    <div className={classes.container}>
                        {appConfig.navBarOpen ? <SideNavBar className={classes.nav} menu={menu}/> : null}

                        <div className={classes.content}>
                            <Switch>
                                {
                                    pages.map(page => <Route basename={page.id} path={page.path} key={page.id}
                                                             exact><Page
                                        config={page.widget}/></Route>)
                                }
                                <Route path={"/case/:reference"}>
                                    <CaseView/>
                                </Route>
                                <Route path="/">
                                    <Redirect to={"/dashboard"}/>
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </ApplicantProvider>
        </Router>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    container: {
        display: 'flex',
        flex: 1,
        backgroundColor: "#F8F9F3",
        maxHeight: '100%',
        overflow: 'hidden'
    },
    nav: {
        display: "inline-block",
        width: '246px'
    },
    content: {
        flex: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        overflow: 'auto',

        // Firefox
        scrollbarWidth: 'thin',
        scrollbarColor: '#999 #fff',

        // Chrome
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            background: '#fff'
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#999'
        },

    }
}));

export default Base;