import React from 'react';
import copy from "copy-to-clipboard";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as CopyIcon} from "./copy.svg";
import styled from "styled-components";

const CopyToClipboard = ({text}) => {

    const onClick = () => {
        copy(text);
    }

    return (
        <StyledIconButton onClick={onClick}>
            <CopyIcon/>
        </StyledIconButton>
    );
};

export default CopyToClipboard;

const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    padding: 0 12px;
  }
`;